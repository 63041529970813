import React, { ChangeEventHandler, forwardRef } from 'react';
import { TextInput } from '@volvo-cars/react-forms';
import { IconButton } from '@volvo-cars/react-icons';
import { useSharedContentTranslate } from '@vcc-www/shared-content/DictionariesProvider';

interface SearchFieldProps {
  loading: boolean;
  focused: boolean;
  touched: boolean;
  setTouched: (touched: boolean) => void;
  required: boolean;
  value: string;
  showingData?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onSearch: () => void;
  onFocus: () => void;
  onBlur: () => void;
  onNavigate: (direction: number) => void;
  hint?: string;
}

export const SearchField = forwardRef<HTMLInputElement, SearchFieldProps>(
  (
    {
      loading,
      focused,
      touched,
      setTouched,
      required,
      value,
      showingData,
      onChange,
      onSearch,
      onNavigate,
      onFocus,
      onBlur,
      hint,
    },
    ref,
  ) => {
    const sharedContentTranslate = useSharedContentTranslate();

    const showError =
      required && touched && !focused && !loading && !showingData;

    const errorMessage = showError
      ? sharedContentTranslate('retailerPicker.requiredError')
      : undefined;

    return (
      <div
        className="flex flex-row items-center gap-8"
        style={{ position: 'relative' }}
      >
        <TextInput
          ref={ref}
          name="address_autocomplete"
          label={sharedContentTranslate('retailerPicker.searchLabel')}
          errorMessage={errorMessage}
          required={required}
          value={value}
          disabled={loading}
          data-testid="retailerPicker.searchInput"
          // TODO: add onKeyDown to react-forms
          // @ts-ignore
          onKeyDown={(ev: React.KeyboardEvent) => {
            if (ev.key === 'Enter') {
              ev.preventDefault();
              onSearch();
            } else if (ev.key === 'Up' || ev.key === 'ArrowUp') {
              onNavigate(-1);
            } else if (ev.key === 'Down' || ev.key === 'ArrowDown') {
              onNavigate(1);
            }
          }}
          onFocus={() => {
            setTouched(true);
            onFocus();
          }}
          onBlur={onBlur}
          onChange={onChange}
          className="flex-grow"
          hint={hint}
        />
        <IconButton
          icon="search"
          className="absolute end-8 top-8"
          type="button"
          variant="clear"
          disabled={loading}
          onClick={onSearch}
          aria-label={sharedContentTranslate(
            'retailerPicker.searchButtonAriaLabel',
          )}
        />
      </div>
    );
  },
);
SearchField.displayName = 'SearchField';
