'use client';

import { Media } from '@components/media';
import {
  errorCategories,
  reportDetailedErrorMessage,
} from '@utils/error-logging/error-logging-messages';
import { getMediaItem } from '../ui-components.utils';
import type { MediaCtUiProps } from './media.types';

// Exporting as default so the naming does not clash
export default function MediaWrapper({
  content,
  contentType,
  responsiveAspectRatio,
}: MediaCtUiProps) {
  if (!content) {
    reportDetailedErrorMessage({
      culprit: 'ContentStack > MediaWrapper UI Component',
      errorCategory: errorCategories.CONTENT_DELIVERY,
      details: {
        missingParts: ['content'],
      },
    });

    return null;
  }
  const { media } = content;
  const mediaItem = getMediaItem(media);

  if (!mediaItem) {
    reportDetailedErrorMessage({
      culprit: 'ContentStack > MediaWrapper UI Component > media',
      errorCategory: errorCategories.CONTENT_DELIVERY,
      details: {
        missingParts: ['contentType'],
      },
    });

    return null;
  }

  return (
    <div className="w-full" data-testid={contentType?.id}>
      <Media
        items={[mediaItem]}
        responsiveAspectRatio={responsiveAspectRatio}
      />
    </div>
  );
}
