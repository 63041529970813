import React from 'react';
import { useSharedContentTranslate } from '@vcc-www/shared-content/DictionariesProvider';

export const GenericErrorMessage = ({
  children,
  visible,
}: {
  children: React.ReactNode;
  visible: boolean;
}) => {
  if (!visible) {
    return null;
  }

  return (
    <div className="border-l-2 p-16 border-feedback-red bg-secondary">
      {children}
    </div>
  );
};

export const ErrorMessage = ({
  onClick,
  visible,
}: {
  onClick?: (ev: React.MouseEvent) => void;
  errorMessageText?: boolean;
  translationsError?: any;
  visible: boolean;
}) => {
  const sharedContentTranslate = useSharedContentTranslate();

  // generic error
  const errorLinkText = sharedContentTranslate(
    'retailerPicker.searchErrorRefreshLink',
  );

  const errorText = sharedContentTranslate('retailerPicker.searchError', {
    searchErrorRefreshLink: errorLinkText,
  });

  const [textBefore = '', textAfter = ''] = errorText.split(errorLinkText);

  return (
    <GenericErrorMessage visible={visible}>
      {textBefore}
      <button type="button" onClick={onClick} className="link-inline">
        {errorLinkText}
      </button>
      {textAfter}
    </GenericErrorMessage>
  );
};
