import { useEffect, useRef, useState } from 'react';
import type {
  PlaceAutocompleteResult,
  GeocodeResult,
} from '@googlemaps/google-maps-services-js';
import { Coordinate, Retailer } from './types';

const isLocalDev = () =>
  process.env.NEXT_PUBLIC_RETAILER_PICKER_MAINTENANCE !== 'true' &&
  typeof window !== 'undefined' &&
  window.location.origin.includes('localhost');

const isAuthoring = () =>
  typeof window !== 'undefined' &&
  window.location.origin.includes('authoring.volvocars.com');

const isPR = () =>
  typeof window !== 'undefined' &&
  window.location.origin.startsWith('https://pr-');

const isStorybook = () =>
  typeof window !== 'undefined' &&
  window.location.origin.includes('storybooks.ccdp.io');

export const getAccessHeader = () => {
  if ((isLocalDev() || isPR()) && process.env.NEXT_PUBLIC_QAWWW_HEADER)
    return {
      QAWWW: process.env.NEXT_PUBLIC_QAWWW_HEADER,
    };
};

const QA_URL = 'https://qawww.volvocars.com';
const PROD_URL = 'https://www.volvocars.com';

export const getOrigin = () => {
  if (isLocalDev() || isPR()) {
    return QA_URL;
  }
  if (isAuthoring() || isStorybook()) {
    return PROD_URL;
  }
  return '';
};

export const fetchAddressLocation = async (
  place_id: string,
  sessiontoken: string,
): Promise<Coordinate> => {
  const searchParams = new URLSearchParams({
    place_id,
    sessiontoken,
  });
  const endpoint = `${getOrigin()}/api/order-request/shared/places/location?${searchParams}`;
  const response = await fetch(endpoint, {
    headers: getAccessHeader(),
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json();
};

export class FetchRetailerError extends Error {
  static errorName = 'FetchRetailerError' as const;
  constructor(message: string) {
    const prefixedMessage = `Fetch retailer error: ${message}`;

    super(prefixedMessage);

    this.name = FetchRetailerError.errorName;

    // Set the prototype explicitly to ensure instanceof works correctly
    Object.setPrototypeOf(this, FetchRetailerError.prototype);

    // Maintain proper stack trace (only available on V8 engines like Node.js)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FetchRetailerError);
    }
  }
}

export const fetchRetailers = async (
  location: Coordinate,
  market: string,
  page: number | null,
  numberPerPage: number,
  capabilities: string[] = [],
  retailerCountryFilter?: string,
): Promise<{
  retailers: Retailer[];
  nextPage: number;
}> => {
  const capabilitiesStr = capabilities.join(',');
  const params: any = {
    lat: location.lat.toString(),
    long: location.lng.toString(),
    numberPerPage: numberPerPage.toString(),
    capabilities: capabilitiesStr,
  };
  if (page) {
    params.page = page.toString();
  }
  const searchParams = new URLSearchParams(params);
  if (retailerCountryFilter) {
    searchParams.set('filterByCountry', retailerCountryFilter);
  }
  const endpoint = `${getOrigin()}/api/order-request/shared/${market}/partners?${searchParams}`;

  const response = await fetch(endpoint, {
    headers: getAccessHeader(),
  });

  const responseData = await response.json();

  if (!response.ok) {
    throw new FetchRetailerError(responseData.toString());
  }
  const { retailers, nextPage } = responseData;

  return {
    retailers,
    nextPage,
  };
};

export const fetchRetailer = async (
  market: string,
  parmaCode: string,
): Promise<Retailer> => {
  const endpoint = `${getOrigin()}/api/order-request/shared/${market}/partners/${parmaCode}`;
  const response = await fetch(endpoint, {
    headers: getAccessHeader(),
  });
  if (!response.ok) {
    throw new FetchRetailerError(response.statusText);
  }
  const data = await response.json();

  // when preselectedParmaCode isn't a partner from the market in the url we get an empty object
  if (JSON.stringify(data) === '{}') {
    throw new FetchRetailerError('Market and partnerParmaCode dont match');
  }
  const partnerOrganizationalUnitFacility =
    data.partnerOrganizationalUnitFacilities?.[0];

  return {
    ...data,
    partnerOrganizationalUnitFacility,
  };
};

export type GetAddressProps =
  | {
      placeId: string;
      coordinate?: undefined;
      language: string;
    }
  | {
      placeId?: undefined;
      coordinate: Coordinate;
      language: string;
    };

export const getAddress = async ({
  placeId,
  coordinate,
  language,
}: GetAddressProps): Promise<GeocodeResult[]> => {
  if (!placeId && !coordinate) {
    throw new Error('Missing params: placeId or coordinate');
  }

  const params = new URLSearchParams();
  if (placeId) {
    params.append('placeId', placeId);
  } else if (coordinate) {
    params.append('lat', coordinate.lat.toString());
    params.append('lng', coordinate.lng.toString());
  }

  if (language) {
    params.append('language', language);
  }

  const url = `${getOrigin()}/api/order-request/shared/places/address?${params.toString()}`;

  const response = await fetch(url, {
    headers: getAccessHeader(),
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }

  const json = await response.json();
  return json.results;
};

export const getPlaceAndLocationBySearch = async (
  search: string,
  countryCode: string | undefined,
  languageCode: string,
) => {
  if (!countryCode) {
    throw new Error('Country code is required');
  }
  const sessionToken = crypto.randomUUID();
  const searchParams = new URLSearchParams({
    search,
    countryCode,
    languageCode,
    sessiontoken: sessionToken,
  });

  const endpoint = `${getOrigin()}/api/order-request/shared/places/autocomplete?${searchParams}`;
  const response = await fetch(endpoint, {
    headers: {
      ...getAccessHeader(),
    },
  });

  if (!response.ok) {
    throw new Error("Couldn't fetch predictions");
  }

  const predictions = await response.json();
  const topPrediction = predictions?.data?.predictions?.[0];

  if (!topPrediction) {
    throw new Error('No predictions found');
  }

  const location = await fetchAddressLocation(
    topPrediction.place_id,
    sessionToken,
  );

  return {
    placeResult: topPrediction,
    location,
  };
};

export const useSessionToken = () => {
  const sessionToken = useRef<string>('');
  const getSessionToken = () => {
    if (!sessionToken.current) {
      sessionToken.current = crypto.randomUUID();
    }
    return sessionToken.current;
  };
  const invalidateSessionToken = () => {
    sessionToken.current = '';
  };

  return { getSessionToken, invalidateSessionToken };
};

export type UseAddressAutoCompleteProps = {
  search: string;
  sessiontoken: string;
  countryCode: string;
  languageCode: string;
  onError?: (reason: string, error: unknown) => void;
  setHasError?: (value: boolean) => void;
};

export const useAddressAutoComplete = ({
  search,
  sessiontoken,
  countryCode,
  languageCode,
  onError,
  setHasError,
}: UseAddressAutoCompleteProps) => {
  const [predictions, setPredictions] = useState<PlaceAutocompleteResult[]>([]);
  const [noMatches, setNoMatches] = useState<boolean>(false);

  const searchParams = new URLSearchParams({
    search,
    countryCode,
    languageCode,
    sessiontoken,
  });

  const endpoint = `${getOrigin()}/api/order-request/shared/places/autocomplete?${searchParams}`;
  useEffect(() => {
    if (search.length === 0) {
      setNoMatches(false);
      setPredictions([]);
      return;
    }

    const controller = new AbortController();
    const { signal } = controller;

    const fetchPredictions = async () => {
      try {
        const response = await fetch(endpoint, {
          signal,
          headers: {
            ...getAccessHeader(),
          },
        });
        const data = await response.json();
        if (!response.ok) {
          throw data;
        }
        const newNoMatches = data?.data?.status === 'ZERO_RESULTS';
        const newPredictions = data?.data?.predictions?.filter(Boolean) ?? [];
        setNoMatches(newNoMatches);
        setPredictions(newPredictions);
      } catch (error: unknown) {
        if ((error as any)?.name !== 'AbortError') {
          onError?.('Failed to load predictions', error);
        }
      }
    };
    void fetchPredictions();

    return () => controller.abort();
  }, [search, endpoint, onError, setHasError]);

  return { predictions, noMatches };
};

export type Dictionaries = {
  'retailerPicker.clearSelection': string;
  'retailerPicker.geoBlocked': string;
  'retailerPicker.info': string;
  'retailerPicker.infoNoGeo': string;
  'retailerPicker.infoLink': string;
  'retailerPicker.myLocation': string;
  'retailerPicker.nextRetailers': string;
  'retailerPicker.requiredError': string;
  'retailerPicker.searchButtonAriaLabel': string;
  'retailerPicker.searchError': string;
  'retailerPicker.searchErrorRefreshLink': string;
  'retailerPicker.searchLabel': string;
  'retailerPicker.noMatches': string;
};

export async function fetchDictionaries(
  data: Record<'market', string>,
): Promise<Dictionaries> {
  const { market } = data;
  const response = await fetch(
    `${getOrigin()}/api/order-request/shared/${market}/retailer-picker`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...getAccessHeader(),
      },
    },
  );

  return response.json();
}
