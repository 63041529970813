import { ProductCard } from './product-card.module';
import styles from './product-card.module.css';
import { ProductCardListViewProps } from './product-card.types';

export function ProductCardListView({ data }: ProductCardListViewProps) {
  return (
    <div className={`${styles['product-card-layout']} gap-x-24 gap-y-24`}>
      {data?.map((card) => <ProductCard key={card.id} {...card} />)}
    </div>
  );
}
