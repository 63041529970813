import Image from '@contentstack/content-types/image/image.ct';
import Video from '@contentstack/content-types/video/video.ct';
import { EditorialComponent } from '@volvo-cars/content-management-client';

const editorialComponent: EditorialComponent = {
  id: 'sfMedia',
  name: 'SF Media',
  namespace: 'sfCampaigns',
  skipPush: true,
  fields: [
    {
      id: 'media',
      name: 'Media',
      type: 'Reference',
      referenceContentTypes: [Image, Video],
      referenceType: 'Entry',
      localized: true,
      required: true,
    },
  ],
  renderingParams: [],
};

export default editorialComponent;
