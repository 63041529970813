'use client';

import type RetailerPickerProps from '@contentstack/editorial-components/retailer-picker/retailer-picker.props';
import { useGA4Tracker } from '@hooks/utils/useGA4Tracker';
import {
  errorCategories,
  reportDetailedErrorMessage,
} from '@utils/error-logging/error-logging-messages';
import { RetailerPicker } from '@vcc-package/retailer-picker';
import type { RegionCode, SiteSlug } from '@volvo-cars/market-sites';

export function RetailerPickerWrapper({
  campaignHandle,
  content,
  contentType,
  siteSlug,
  regionCode,
}: RetailerPickerProps & {
  campaignHandle: string | undefined;
  regionCode: RegionCode;
  siteSlug: SiteSlug;
}) {
  const tracker = useGA4Tracker();

  const { title } = content;

  if (!title) {
    reportDetailedErrorMessage({
      culprit: 'ContentStack > RetailerPickerWrapper UI Component',
      errorCategory: errorCategories.CONTENT_DELIVERY,
      details: {
        missingParts: ['title'],
      },
    });

    return null;
  }

  return (
    <div className="py-48" data-testid={contentType?.id}>
      <div className="flex justify-center">
        <h2 className="heading-2 font-medium mb-48">{content.title}</h2>
      </div>
      <div className="container-sm">
        <RetailerPicker
          config={{
            isoCountryCode: regionCode?.toLowerCase(),
            volvoCountryCode: siteSlug,
            googleCountryCode: regionCode?.toLowerCase(),
          }}
          onError={(reason: string, error: unknown) =>
            reportDetailedErrorMessage({
              culprit: 'RetailerPickerWrapper',
              errorCategory: errorCategories.GENERAL,
              details: {
                message: `${reason} | ${error}`,
              },
            })
          }
          tracking={{
            onSearch: {
              eventAction: 'search',
              eventCategory: 'agent partner search',
            },
            onGeoClicked: {
              eventCategory: 'agent partner search',
              eventLabel: 'use your location',
              eventAction: 'click',
            },
          }}
          retailerSelected={(retailer) => {
            tracker.customEvent({
              eventAction: 'input field|click',
              eventLabel: `Campaign: ${campaignHandle} | Retailer Picker: ${retailer}`,
              eventCategory: 'sfRetailerPicker',
            });
          }}
        />
      </div>
    </div>
  );
}
