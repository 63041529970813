import { ImageWithFallback } from '@components/image-with-fallback/image-with-fallback.module';
import styles from './product-card.module.css';
import { ProductCardProps } from './product-card.types';

export function ProductCard({
  displayName,
  fullSizeImg = true,
  handleOnClick,
  id,
  mediaItem,
  navigationUrl,
  price,
  smAlternateAspectRatio = false,
  textAlignStart = false,
}: ProductCardProps) {
  return (
    <a
      className={`${styles['product-card-link']} link-plain`}
      href={navigationUrl}
      key={id}
      onClick={handleOnClick}
      data-testid="product-card"
    >
      <div className={`${textAlignStart && 'text-start'} mb-16 gap-16`}>
        <div
          className={`${smAlternateAspectRatio ? `${styles['product-card-img-container-sm-alternate-aspect-ratio']} md:aspect-4/3` : 'aspect-4/3'} overflow-hidden md:mb-16`}
        >
          <ImageWithFallback
            alt={displayName ? `${displayName} image` : 'Product image'}
            className={`object-cover  ${!fullSizeImg ? styles['product-card-img-sm-full'] : 'w-full h-full'}`}
            fallbackImage="spread-word-mark"
            height={294}
            priority
            src={mediaItem}
            width={392}
            sourceComponent="ProductCardView"
          />
        </div>
        {/* This makes the card always have the with of the image and forces text to wrap */}
        <div className="w-0 min-w-fit">
          <p className="font-medium mb-8 mt-16">{displayName}</p>
          <p className="inline flow-root mb-8">{price}</p>
        </div>
      </div>
    </a>
  );
}
