'use client';

import { useEffect, useRef, useState } from 'react';
import type AnimatedCounterProps from '@contentstack/editorial-components/animated-counter/animated-counter.props';
import type MarkdownTextProps from '@contentstack/editorial-components/markdown-text/markdown-text.props';
import { MarkdownText } from '../markdown-text/markdown-text';
import styles from './animated-counter.module.css';

// An exclusive component for the Winter Accessories page
export function AnimatedCounter({
  contentType,
  content,
}: AnimatedCounterProps) {
  const [isInView, setIsInView] = useState(false);
  const [counterValue, setCounterValue] = useState(0);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIsInView(!!entry?.isIntersecting),
      { threshold: 1 },
    );

    const container = containerRef.current;

    if (container) {
      observer.observe(container);
    }

    if (isInView && container) {
      observer.unobserve(container);
    }

    return () => {
      if (container) {
        observer.unobserve(container);
      }
    };
  }, [isInView]);

  useEffect(() => {
    if (isInView) {
      let interval: NodeJS.Timeout;
      if (counterValue < content.counterFinalValued) {
        interval = setInterval(() => {
          setCounterValue((prevCounterValue) => prevCounterValue + 1);
        }, 75);
      }
      return () => clearInterval(interval);
    }
  }, [content.counterFinalValued, counterValue, isInView]);

  const { markdownText } = content;

  return (
    <div
      ref={containerRef}
      className="text-center container py-m"
      data-testid={contentType?.id}
    >
      <h3 className={`heading-1 font-medium ${isInView && styles['counter']}`}>
        {counterValue}
        <sup>°</sup>
      </h3>
      {/* TODO Revisit casting when propsgenerations has been updated */}
      {markdownText && (
        <MarkdownText {...(markdownText as unknown as MarkdownTextProps)} />
      )}
    </div>
  );
}
