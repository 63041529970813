import { ProductCardListView } from './product-card-list.view';
import { ProductCardListProps } from './product-card.types';

export function ProductCardList({ data }: ProductCardListProps) {
  return (
    <div data-testid="product-card">
      <ProductCardListView data={data} />
    </div>
  );
}
