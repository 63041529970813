import type CTAProps from '@contentstack/editorial-components/cta/cta.props';
import type MarkdownTextProps from '@contentstack/editorial-components/markdown-text/markdown-text.props';
import markdownit from 'markdown-it';
import { CTA } from '../cta/cta';

const md = markdownit({
  html: true,
});

export function MarkdownText({
  content,
  contentType,
  isCentered,
}: MarkdownTextProps) {
  const { cta, markdownText } = content;

  return (
    <div className="stack-32 container-md" data-testid={contentType?.id}>
      <div
        className={`${isCentered ? 'text-center' : ''} font-20`}
        dangerouslySetInnerHTML={{ __html: md.render(markdownText) }}
      />
      {/* TODO Revisit casting when propsgenerations has been updated */}
      {cta && (
        <CTA
          trackingEventCategory="sfMarkdownText"
          {...(cta as unknown as CTAProps)}
        />
      )}
    </div>
  );
}
