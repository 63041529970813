import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { PlaceAutocompleteResult } from '@googlemaps/google-maps-services-js';
import {
  Dictionaries,
  fetchAddressLocation,
  useAddressAutoComplete,
  useSessionToken,
} from './dataFetching';
import { SearchField } from './SearchField';
import { Predictions } from './Predictions';
import { useCurrentMarketSite } from '@vcc-www/market-sites';
import { useSharedContentTranslate } from '@vcc-www/shared-content/DictionariesProvider';
import { getMarketSite, SiteSlug } from '@volvo-cars/market-sites';
import { Coordinate } from './types';
import { GeoLocationAndInfoMessage } from './GeoLocationAndInfoMessage';
import { ErrorMessage } from './ErrorMessage';
import { RetailerPickerTranslationProvider } from './RetailerPickerTranslationProvider';

/**
 * Optional override of using current market site
 */
export interface RetailerPickerConfig {
  isoCountryCode: string | undefined; // Used by retailer api
  volvoCountryCode: SiteSlug | undefined; // Used for translations
  googleCountryCode: string | undefined; // Used for google places lookup
}

interface PlacesInnerProps {
  loading?: boolean;
  required?: boolean;
  showingData?: boolean;
  onAutoCompleteChange?: (search: string) => void;
  onLocationSearchStart?: () => void;
  onLocationSearchEnd?: () => void;
  onLocationFound: (
    location: Coordinate,
    placeResult: PlaceAutocompleteResult | null,
    fromGeo: boolean,
  ) => void;
  onFocusChange?: (isFocused: boolean) => void;
  onFetchAddressError?: (error: unknown) => void;
  onReset?: () => void;
  onError?: (reason: string, error: unknown) => void;
  config?: RetailerPickerConfig;
  hasError?: boolean;
  enableGeo?: boolean;
  enableInfoMessage?: boolean;
  hint?: string;
}

export interface PlacesHandle {
  input: HTMLInputElement | null;
  touch: () => void;
  reset: () => void;
  setSearch: (newSearch: string) => void;
}

export const PlacesInner = forwardRef<PlacesHandle, PlacesInnerProps>(
  (
    {
      loading,
      required,
      showingData,
      onAutoCompleteChange,
      onLocationSearchStart,
      onLocationSearchEnd,
      onLocationFound,
      onFetchAddressError,
      onFocusChange,
      onReset,
      onError,
      config,
      hasError = false,
      enableGeo = true,
      enableInfoMessage = true,
      hint,
    },
    ref,
  ) => {
    const [touched, setTouched] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [search, setSearch] = useState('');
    const [hasGeo, setHasGeo] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const { getSessionToken, invalidateSessionToken } = useSessionToken();
    const searchFieldRef = useRef<HTMLInputElement>(null);

    const sharedContentTranslate = useSharedContentTranslate();

    useImperativeHandle(ref, () => ({
      input: searchFieldRef.current,
      reset,
      setSearch,
      touch: () => setTouched(true),
    }));

    const reset = () => {
      setHasGeo(false);
      setTouched(false);
      setSearch('');
      setCurrentIndex(0);
      invalidateSessionToken();
    };

    const { siteSlug, regionCode } = useCurrentMarketSite();
    const {
      volvoCountryCode = siteSlug || 'siteSlug-undefined',
      googleCountryCode = regionCode || 'regionCode-undefined',
    } = config || ({} as any);
    const { languageCode } = getMarketSite(volvoCountryCode);

    const { predictions, noMatches } = useAddressAutoComplete({
      search,
      sessiontoken: getSessionToken(),
      countryCode: googleCountryCode,
      languageCode,
    });

    const onSelectPrediction = async (prediction: PlaceAutocompleteResult) => {
      try {
        if (prediction) {
          const { place_id, description } = prediction;
          searchFieldRef.current?.blur();
          setSearch(description);

          // Update loading status in client
          onLocationSearchStart?.();
          const location = await fetchAddressLocation(
            place_id,
            getSessionToken(),
          );
          invalidateSessionToken();

          // Update client
          onLocationFound(location, prediction, false);
        }
      } catch (error) {
        onFetchAddressError?.(error);
      }
      onLocationSearchEnd?.();
    };

    const onGeoSelected = async (pos: Coordinate) => {
      setHasGeo(true);
      searchFieldRef.current?.blur();
      setSearch(sharedContentTranslate('retailerPicker.myLocation'));
      invalidateSessionToken();
      onLocationFound(pos, null, true);
    };

    const onKeyboardNavigation = (direction: number) => {
      const positiveMod = (value: number, mod: number) =>
        ((value % mod) + mod) % mod;

      const newIndex = positiveMod(
        currentIndex + direction,
        predictions.length,
      );
      setCurrentIndex(newIndex);
    };

    const onFocusChanged = (newIsFocused: boolean) => {
      if (newIsFocused && hasGeo) {
        onSearchChanged('');
      }
      setIsFocused(newIsFocused);
      onFocusChange?.(newIsFocused);
    };

    const onSearchChanged = (newSearch: string) => {
      setHasGeo(false);
      setSearch(newSearch);
      onAutoCompleteChange?.(newSearch);
    };

    return (
      <>
        <SearchField
          ref={searchFieldRef}
          loading={Boolean(loading)}
          focused={isFocused}
          onFocus={() => onFocusChanged(true)}
          onBlur={() => onFocusChanged(false)}
          touched={touched}
          setTouched={setTouched}
          required={Boolean(required)}
          value={search}
          onChange={(e) => onSearchChanged(e.target.value)}
          showingData={showingData}
          onSearch={() => void onSelectPrediction(predictions[currentIndex])}
          onNavigate={onKeyboardNavigation}
          hint={hint}
        />

        <ErrorMessage visible={hasError} onClick={() => onReset?.()} />

        <GeoLocationAndInfoMessage
          enableGeo={enableGeo}
          visible={Boolean(!hasError && enableInfoMessage)}
          onError={(error: unknown) => onError?.('Getting geo location', error)}
          onBlocked={() => {
            onReset?.();
          }}
          searchFieldFocused={isFocused}
          hasLocation={Boolean(loading || showingData)}
          onSelected={onGeoSelected}
        />

        <Predictions
          visible={isFocused && !hasGeo}
          noMatches={noMatches}
          predictions={predictions}
          currentIndex={currentIndex}
          onClick={(prediction) => {
            void onSelectPrediction(prediction);
          }}
          onMouseEnter={(index) => setCurrentIndex(index)}
        />
      </>
    );
  },
);

PlacesInner.displayName = 'PlacesInner';

export interface PlacesProps extends PlacesInnerProps {
  market?: SiteSlug;
  externalTranslations?: Dictionaries;
}

export const Places = forwardRef<PlacesHandle, PlacesProps>(
  ({ market, externalTranslations, ...props }, ref) => {
    const { siteSlug } = useCurrentMarketSite();
    const marketLanguage = market || siteSlug;

    if (marketLanguage === undefined) {
      throw new Error('Missing market');
    }

    return (
      <RetailerPickerTranslationProvider
        market={marketLanguage}
        externalTranslations={externalTranslations}
      >
        <PlacesInner {...props} ref={ref} />
      </RetailerPickerTranslationProvider>
    );
  },
);

Places.displayName = 'Places';
