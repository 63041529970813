'use client';

import React from 'react';
import { useCarousel } from '../providers/CarouselProvider';
import { cssMerge } from '@volvo-cars/css/utils';
import { Track } from '@volvo-cars/tracking';

const CarouselFilter = ({
  allLabel,
  className,
  ...props
}: { allLabel: string } & React.HTMLAttributes<HTMLDivElement>) => {
  const {
    updateFilter,
    filter: activeFilter,
    filters,
    totalAmountOfCards,
  } = useCarousel();

  const uniqueFilters = [...new Set(filters)];
  if (uniqueFilters.length <= 1) return null; // Do not render if there is only one filter

  const filterWithAmount = filters?.reduce(
    (acc: Record<string, number>, filter) => {
      acc[filter] = (acc[filter] || 0) + 1;
      return acc;
    },
    {},
  );

  return (
    <div
      className={cssMerge(
        'flex gap-8 -mx-8 overflow-auto scrollbar-none',
        className,
      )}
      id=":r0:"
      data-autoid="carousel:filter"
      role="tablist"
      {...props}
    >
      {[allLabel, ...Object.keys(filterWithAmount)].map((label, i) => {
        const amount =
          label === allLabel ? totalAmountOfCards : filterWithAmount[label];
        return (
          <Track key={label} eventLabel={label} eventAction="filter|select">
            <button
              style={{ whiteSpace: 'nowrap' }}
              type="button"
              className="px-8 font-medium tap-area"
              id={`":r0:tab${i}"`}
              role="tab"
              tabIndex={-1}
              aria-selected={
                activeFilter === label || (label === allLabel && !activeFilter)
              }
              onClick={() =>
                updateFilter(label === allLabel ? undefined : label)
              }
            >
              <div className="flex items-center h-48 border-b-2 border-transparent tab-selected:border-primary transition-colors">
                {label} ({amount})
              </div>
            </button>
          </Track>
        );
      })}
    </div>
  );
};

export default CarouselFilter;
