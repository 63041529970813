import React, { useEffect, useState } from 'react';
import { useSharedContentTranslate } from '@vcc-www/shared-content/DictionariesProvider';
import { Coordinate } from './types';
import Message from './Message';
import { GenericErrorMessage } from './ErrorMessage';

const getCurrentLocation = (): Promise<Coordinate> => {
  if (!navigator?.geolocation) {
    throw new Error('Geolocation is not supported by your web browser');
  }

  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition((pos) => {
      resolve({ lat: pos.coords.latitude, lng: pos.coords.longitude });
    }, reject);
  });
};

type GeoLocationProps = {
  searchFieldFocused: boolean;
  hasLocation: boolean;
  onSelected: (pos: Coordinate) => void;
  visible: boolean;
  onError?: (reason: string, error: unknown) => void;
  onBlocked: () => void;
  enableGeo: boolean;
};

export const GeoLocationAndInfoMessage = ({
  searchFieldFocused,
  hasLocation,
  onSelected,
  visible,
  onError,
  onBlocked,
  enableGeo,
}: GeoLocationProps) => {
  const [recentlyBlocked, setRecentlyBlocked] = useState(false);

  const sharedContentTranslate = useSharedContentTranslate();

  useEffect(() => {
    if (searchFieldFocused) {
      setRecentlyBlocked(false);
    }
  }, [searchFieldFocused]);

  const locationLinkVisible = searchFieldFocused;
  const infoMessageVisible =
    !searchFieldFocused && !hasLocation && !recentlyBlocked;

  const onGeoClicked = async (ev: React.MouseEvent) => {
    ev.preventDefault();
    try {
      const pos = await getCurrentLocation();
      void onSelected(pos);
    } catch (error) {
      if (error instanceof GeolocationPositionError) {
        setRecentlyBlocked(true);
        onBlocked();
      } else {
        onError?.('Geo location error', error);
      }
    }
  };

  const noContent =
    !locationLinkVisible && !recentlyBlocked && !infoMessageVisible;

  const showGeoBlocked = recentlyBlocked && !hasLocation;

  if (!visible || noContent) {
    return null;
  }

  if (!enableGeo) {
    if (!infoMessageVisible) {
      return null;
    }
    return (
      <div className="border-l-2 p-16 border-accent-blue bg-secondary">
        {sharedContentTranslate('retailerPicker.infoNoGeo')}
      </div>
    );
  }

  return (
    <>
      {infoMessageVisible && <Message onClick={onGeoClicked} />}

      <GenericErrorMessage visible={showGeoBlocked}>
        {sharedContentTranslate('retailerPicker.geoBlocked')}
      </GenericErrorMessage>

      {locationLinkVisible && (
        <button
          type="button"
          className="button-text"
          data-color="accent"
          data-arrow="none"
          onClick={onGeoClicked}
          onMouseDown={(ev) => ev.preventDefault()}
        >
          {sharedContentTranslate('retailerPicker.myLocation')}
        </button>
      )}
    </>
  );
};
GeoLocationAndInfoMessage.displayName = 'GeoLocationAndInfoMessage';
