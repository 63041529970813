import { SharedContentDictionariesProvider } from '@vcc-www/shared-content/DictionariesProvider';
import { type Dictionaries, fetchDictionaries } from './dataFetching';
import React, { ReactNode, useEffect, useState } from 'react';

export const RetailerPickerTranslationProvider = ({
  externalTranslations,
  market,
  children,
}: {
  externalTranslations?: Dictionaries;
  market: string;
  children: ReactNode;
}) => {
  const [translations, setTranslations] = useState<Dictionaries>({
    'retailerPicker.clearSelection': '',
    'retailerPicker.geoBlocked': '',
    'retailerPicker.info': '',
    'retailerPicker.infoNoGeo': '',
    'retailerPicker.infoLink': '',
    'retailerPicker.myLocation': '',
    'retailerPicker.nextRetailers': '',
    'retailerPicker.requiredError': '',
    'retailerPicker.searchButtonAriaLabel': '',
    'retailerPicker.searchError': '',
    'retailerPicker.searchErrorRefreshLink': '',
    'retailerPicker.searchLabel': '',
    'retailerPicker.noMatches': '',
  });

  useEffect(() => {
    if (externalTranslations) {
      return;
    }

    (async () => {
      const dictionaries = await fetchDictionaries({ market });
      setTranslations(dictionaries);
    })();
  }, [externalTranslations, market]);

  const dictionaries = externalTranslations || translations;

  return (
    <SharedContentDictionariesProvider
      dictionaries={dictionaries}
      locale={market}
    >
      {children}
    </SharedContentDictionariesProvider>
  );
};
