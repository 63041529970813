import { ContentType } from '@volvo-cars/content-management-client';

const contentType: ContentType = {
  id: 'sfImage',
  name: 'SF Image',
  namespace: 'sfCampaigns',
  skipPush: true,
  fields: [
    {
      id: 'imageAsset',
      name: 'Image asset',
      type: 'Reference',
      referenceType: 'Image',
      required: true,
      localized: true,
    },
  ],
};
export default contentType;
