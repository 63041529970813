import type { MediaProps } from '@components/media';
import type { ProductCardProps } from '@components/product-card/product-card.types';
import imageCT from '@contentstack/content-types/image/image.ct';
import type ImageProps from '@contentstack/content-types/image/image.props';
import productCT from '@contentstack/content-types/product/product.ct';
import type ProductProps from '@contentstack/content-types/product/product.props';
import {
  errorCategories,
  reportDetailedErrorMessage,
} from '@utils/error-logging/error-logging-messages';
import { getMediaItem } from '../ui-components.utils';

export function parseMediaList(mediaList: ProductProps['mediaList']) {
  const parsedList: MediaProps['items'] = mediaList
    .map((item) => getMediaItem(item.media))
    .filter(Boolean);
  return parsedList;
}

type CompatibilityList = Array<{
  icon: { src: string; alt: string };
  text: string;
}>;

export function parseCompatibility({
  list,
  productSlug,
}: {
  list: ProductProps['compatibilityList'];
  productSlug: string;
}) {
  if (!list) return null;

  const initCompatibilityList: CompatibilityList = [];
  const compatibilityList = list?.reduce((prev, item) => {
    const { icon, text } = item;

    if (!icon || !text) {
      reportDetailedErrorMessage({
        culprit: `ContentStack > ProductCardModal UI Component > parseCompatibility function for Product "${productSlug}"`,
        errorCategory: errorCategories.CONTENT_DELIVERY,
        details: {
          missingParts: ['icon', 'text'],
        },
      });

      return prev;
    }

    const compatibilityItem = {
      icon: { src: item.icon.src, alt: item.icon.alt },
      text: item.text,
    };
    return [...prev, compatibilityItem];
  }, initCompatibilityList);
  return compatibilityList;
}

export function parseProduct({
  productSlug,
  productName,
  priceOrText,
  mediaList,
}: ProductProps) {
  const mediaItem = mediaList.find(
    ({ media }) => media.contentType?.id === imageCT.id,
  );
  if (!mediaItem) return null;

  const imageMedia = mediaItem.media as ImageProps;
  const product: ProductCardProps = {
    handle: productSlug,
    id: productSlug,
    price: priceOrText,
    mediaItem: imageMedia.imageAsset.src,
    displayName: productName,
    navigationUrl: `#${productSlug}`,
  };

  return product;
}

export function productHasRequiredFields(
  product: ProductProps,
  uiComponentName: string,
) {
  const { contentType, mediaList, productSlug, productName } = product;

  if (typeof contentType === 'string' && contentType !== productCT.id) {
    reportDetailedErrorMessage({
      culprit: `ContentStack > ${uiComponentName} UI Component`,
      errorCategory: errorCategories.CONTENT_DELIVERY,
      details: {
        missingParts: ['product'],
      },
    });

    return false;
  }

  if (!productSlug || !productName) {
    reportDetailedErrorMessage({
      culprit: `ContentStack > ${uiComponentName} UI Component`,
      errorCategory: errorCategories.CONTENT_DELIVERY,
      details: {
        missingParts: ['productSlug', 'productName'],
      },
    });

    return false;
  }

  if (!mediaList || !mediaList.length) {
    reportDetailedErrorMessage({
      culprit: `ContentStack > ${uiComponentName} UI Component > Product "${productSlug}"`,
      errorCategory: errorCategories.CONTENT_DELIVERY,
      details: {
        missingParts: ['mediaList'],
      },
    });

    return false;
  }

  return true;
}
