import Media from '@contentstack/editorial-components/media/media.ct';
import type { ContentType } from '@volvo-cars/content-management-client';
import IconWithText from '../icon-with-text/icon-with-text.ct';

const contentType: ContentType = {
  id: 'sfProduct',
  name: 'SF Product',
  namespace: 'sfCampaigns',
  skipPush: true,
  fields: [
    {
      id: 'mediaList',
      name: 'Media List',
      type: 'ReferenceArray',
      referenceContentTypes: [Media],
      referenceType: 'Entry',
      localized: true,
      required: true,
      helpText:
        'A list of media used in the modal carousel. The first item will be used in the product card',
    },
    {
      id: 'productSlug',
      name: 'Product Slug',
      type: 'ShortText',
      localized: false,
      required: true,
      helpText:
        'The unique slug for the product, eg: Volvo teddy bear -> volvo-teddy-bear',
    },
    {
      id: 'productName',
      name: 'Product name',
      type: 'ShortText',
      localized: true,
      required: true,
    },
    {
      id: 'priceOrText',
      name: 'Price or alternative text',
      type: 'ShortText',
      localized: true,
      required: false,
      helpText: 'This will be displayed wherever price is, eg the product card',
    },
    {
      id: 'description',
      name: 'description',
      type: 'Text',
      localized: true,
      required: false,
      helpText: 'The description rendered in the product modal',
    },
    {
      id: 'compatibilityList',
      name: 'Compatibility List',
      type: 'ReferenceArray',
      referenceType: 'Entry',
      referenceContentTypes: [IconWithText],
      localized: true,
      required: false,
      helpText:
        'A list of icons and texts to show compatibility, shown in the product modal',
    },
  ],
};

export default contentType;
