import(/* webpackMode: "eager", webpackExports: ["AnimatedCounter"] */ "/web/apps/storefront/src/contentstack/ui-components/animated-counter/animated-counter.tsx");
;
import(/* webpackMode: "eager" */ "/web/apps/storefront/src/contentstack/ui-components/campaign-page-slot-entry/campaign-page-slot-entry.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["Image"] */ "/web/apps/storefront/src/contentstack/ui-components/media/image.tsx");
;
import(/* webpackMode: "eager" */ "/web/apps/storefront/src/contentstack/ui-components/media/media.tsx");
;
import(/* webpackMode: "eager" */ "/web/apps/storefront/src/contentstack/ui-components/product-card-wrapper/product-card-wrapper.tsx");
;
import(/* webpackMode: "eager" */ "/web/apps/storefront/src/contentstack/ui-components/product-row/product-row.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["RetailerPickerWrapper"] */ "/web/apps/storefront/src/contentstack/ui-components/retailer-picker-wrapper/retailer-picker-wrapper.tsx");
;
import(/* webpackMode: "eager" */ "/web/apps/storefront/src/utils/imageLoader.ts");
;
import(/* webpackMode: "eager" */ "/web/node_modules/@volvo-cars/tracking/dist/index.cjs");
;
import(/* webpackMode: "eager" */ "/web/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/web/shared/carousel/src/Carousel.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/web/shared/carousel/src/components/Filter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/web/shared/carousel/src/components/Indicator.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/web/shared/carousel/src/components/Navigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/web/shared/carousel/src/components/Slider.tsx");
;
import(/* webpackMode: "eager" */ "/web/shared/carousel/src/providers/CarouselProvider.tsx");
;
import(/* webpackMode: "eager" */ "/web/shared/discovery-cards/src/discovery-card.module.css");
;
import(/* webpackMode: "eager" */ "/web/shared/media/src/BaseImage.tsx");
;
import(/* webpackMode: "eager" */ "/web/shared/promotions/src/promotion-inline/PromotionInline.module.css");
;
import(/* webpackMode: "eager" */ "/web/shared/promotions/src/promotion-display/PromotionDisplay.module.css");
;
import(/* webpackMode: "eager" */ "/web/apps/storefront/app/[siteSlug]/collections/[campaignName]/page.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["PromotionalBanner"] */ "/web/shared/promotions/src/promotional-banner/PromotionalBanner.tsx");
