'use client';

import { useState } from 'react';
import { Media, MediaProps } from '@components/media';
import type ImageProps from '@contentstack/content-types/image/image.props';
import { useGA4Tracker } from '@hooks/utils/useGA4Tracker';
import { Dialog, DialogMain } from '@vcc-package/overlays';
import { MediaCtUiAspectRatio } from './media.types';

type ImageMediaProps = {
  imageAsset: ImageProps;
  responsiveAspectRatio?: MediaCtUiAspectRatio;
};

export function Image({ imageAsset, responsiveAspectRatio }: ImageMediaProps) {
  const image = imageAsset.imageAsset;

  const [isOpen, setIsOpen] = useState(false);
  const tracker = useGA4Tracker();

  if (!imageAsset.imageAsset) {
    return null;
  }

  const mediaItem: MediaProps['items'][number] = {
    alt: image.alt,
    imageSources: {
      default: image.src,
    },
  };

  function handleClick() {
    setIsOpen(true);

    tracker.customEvent({
      eventAction: 'button|click',
      eventLabel: `Image Media Alt: ${image.alt}`,
      eventCategory: 'sfImage',
    });
  }

  return (
    <>
      <button type="button" className="w-full m-8" onClick={handleClick}>
        <Media
          items={[mediaItem]}
          responsiveAspectRatio={responsiveAspectRatio}
        />
      </button>
      <ImageModal
        items={[mediaItem]}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
}

type ImageModalProps = {
  open: boolean;
  onClose: () => void;
} & MediaProps;

function ImageModal({ open, onClose, ...mediaProps }: ImageModalProps) {
  return (
    <Dialog open={open} onClose={onClose} size="full">
      <DialogMain>
        <Media {...mediaProps} />
      </DialogMain>
    </Dialog>
  );
}
